import React from 'react';
import { useContext } from 'react';
import classes from './WordList.module.scss';
import globalContext from '../context/contextus/globalContext';
import { useHistory } from 'react-router-dom';
import { createSingleWordDoc } from '../helper/createSingleWordDoc';

import { AiFillFileWord } from 'react-icons/ai';

const WordList = ({ item, setModal, modal }) => {
  const GlobalContext = useContext(globalContext);

  const { loading, listWordById, word: newWord } = GlobalContext;

  const { word } = item;

  const history = useHistory();

  const onClickGenerateWordDoc = (e) => {
    e.stopPropagation();
    createSingleWordDoc(item);
  };

  const onClickAddModalHandeler = (e) => {
    e.stopPropagation();
    setModal({ ...modal, condition: true, id: item._id });
  };

  const editHandeler = async (id, e) => {
    e.stopPropagation();
    listWordById(id);

    setTimeout(() => {
      history.push(`/edit/${id}`);
    }, 700);
  };

  if (!loading) {
    return (
      <div className={classes.item} onClick={(e) => editHandeler(item._id, e)}>
        <p>{word.german.value}</p>
        <div className={classes.icons}>
          {/* <i
            className='fa-solid fa-pencil'
            onClick={() => editHandeler(item._id)}
          ></i> */}
          <i
            className='fa-solid fa-trash'
            onClick={(e) => onClickAddModalHandeler(e)}
          ></i>
          <AiFillFileWord
            onClick={(e) => onClickGenerateWordDoc(e)}
            className={classes.btn}
          />
        </div>
      </div>
    );
  } else return null;
};

export default WordList;
