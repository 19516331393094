import React, { useContext, useState } from 'react';
import classes from './WordsResults.module.scss';
import globalContext from '../../context/contextus/globalContext';
import { useHistory } from 'react-router-dom';
import Spinner from '../spinner/Spinner';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs';
import { useEffect } from 'react';

const WordsResults = ({ changeLanguageName }) => {
  const GlobalContext = useContext(globalContext);

  const {
    searchResult,
    language,
    selectedLanguage,
    smallLoading,
    listWordById,
  } = GlobalContext;

  const history = useHistory();

  const [showMore, setShowMore] = useState(2);

  const onClickShowMore = (e, num) => {
    e.stopPropagation();
    if (showMore === 2) {
      setShowMore(num);
    } else {
      setShowMore(2);
    }
  };

  useEffect(() => {
    setShowMore(2);
  }, [searchResult]);

  const onClickFilterData = (result, category) => {
    listWordById(result._id);
    /* history.push(`${changeLanguageName}/${category}/${searchTerm}`); */

    const word = result.word[language].value.toLowerCase();

    const checkSeparator = word.includes('/')
      ? word.lastIndexOf('/')
      : word.lastIndexOf('');

    history.push(
      `${changeLanguageName}/${category.replace(/\s/g, '+')}/${word
        .replace(/\s/g, '-')
        .slice(0, checkSeparator)}`
    );
  };

  return (
    <div className={classes.results}>
      {smallLoading ? (
        <Spinner />
      ) : (
        <>
          {searchResult.length === 0 && <p>Kann kein Wort finden</p>}

          {searchResult &&
            searchResult?.map((result) => (
              <div className={classes.items} key={result._id}>
                <div>
                  <div className={classes.items}>
                    {(result.phrasen.length > 0 &&
                      result.phrasen[0]?.english.value === '' &&
                      result.worterbuch?.length < 1 &&
                      result.worterbuch?.length !== 0 &&
                      selectedLanguage === 'B/K/S - ENGLISCH') ||
                    (result.phrasen.length > 0 &&
                      result.phrasen[0]?.english.value === '' &&
                      result.worterbuch.length === 0 &&
                      result.worterbuch.length < 1 &&
                      selectedLanguage === 'ENGLISCH - B/K/S') ||
                    (result.fremdworterbuch.length > 0 &&
                      result.fremdworterbuch[0]?.english.value === '' &&
                      selectedLanguage === 'B/K/S - ENGLISCH') ||
                    (result.fremdworterbuch.length > 0 &&
                      result.fremdworterbuch[0]?.english.value === '' &&
                      selectedLanguage === 'ENGLISCH - B/K/S') ? (
                      <p>Kann kein Wort finden</p>
                    ) : (
                      <>
                        {language === 'german' ? (
                          <div className={classes.heading}>
                            <h4>{result.word?.german.value} - </h4>
                            {'  '}
                            <h4>
                              {selectedLanguage === 'DEUTSCH - B/K/S' &&
                                result.word[language === 'german' && 'bosnian']
                                  .value}
                              {selectedLanguage === 'B/K/S - ENGLISCH' &&
                                result.word[language === 'english' && 'bosnian']
                                  .value}
                            </h4>
                          </div>
                        ) : (
                          <div className={classes.heading}>
                            <h4>{result.word[language].value} - </h4>{' '}
                            {selectedLanguage === 'B/K/S - ENGLISCH' ? (
                              <h4>{result.word.english.value}</h4>
                            ) : (
                              <h4>
                                {
                                  result.word[
                                    language === 'bosnian'
                                      ? 'german'
                                      : 'bosnian'
                                  ].value
                                }
                              </h4>
                            )}
                            {/* <h4>{result.word.german.value}</h4> */}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {result.worterbuch?.length > 0 &&
                    result.worterbuch[0].german.value !== '' && (
                      <div
                        className={classes.item}
                        onClick={() => onClickFilterData(result, 'wörterbuch')}
                      >
                        <h3>Wörterbuch</h3>
                        <p
                          style={{
                            marginBottom: '5px',
                          }}
                        >
                          {
                            result.worterbuch[0][language].value
                              .split('/')
                              .slice(0, 1)[0]
                          }
                        </p>
                      </div>
                    )}

                  {/*Phrasen 
                   {
                          result.worterbuch[0][language].value
                            .split(' ')
                            .slice(0, 1)[0]
                        }
                  
                  */}

                  {result.phrasen.length > 0 &&
                    result.phrasen[0]?.german.value !== '' &&
                    selectedLanguage === 'B/K/S - DEUTSCH' && (
                      <div
                        className={classes.item}
                        onClick={() => onClickFilterData(result, 'phrasen')}
                      >
                        <h3>Phrasen u. Anwendungsbeispiele</h3>
                        {result.phrasen
                          .slice(0, showMore)
                          .map((phrasen, index) => (
                            <p key={index * 2} style={{ margin: 0 }}>
                              {phrasen[language].value}
                            </p>
                          ))}
                        {result.phrasen.length > 2 && showMore === 2 && '...'}
                        {result.phrasen.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.phrasen.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {result.phrasen.length > 0 &&
                    result.phrasen[0]?.german.value !== '' &&
                    selectedLanguage === 'DEUTSCH - B/K/S' && (
                      <div
                        className={classes.item}
                        onClick={() => onClickFilterData(result, 'phrasen')}
                      >
                        <h3>Phrasen u. Anwendungsbeispiele</h3>
                        {result.phrasen
                          .slice(0, showMore)
                          .map((phrasen, index) => (
                            <p key={index * 2}>{phrasen[language].value}</p>
                          ))}
                        {result.phrasen.length > 2 && showMore === 2 && '...'}
                        {result.phrasen.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.phrasen.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {result.phrasen.length > 0 &&
                    result.phrasen[0]?.english.value !== '' &&
                    selectedLanguage === 'B/K/S - ENGLISCH' && (
                      <div
                        className={classes.item}
                        onClick={() => onClickFilterData(result, 'phrasen')}
                      >
                        <h3>Phrasen u. Anwendungsbeispiele</h3>
                        {result.phrasen
                          .slice(0, showMore)
                          .map((phrasen, index) => (
                            <p key={index * 2}>{phrasen[language].value}</p>
                          ))}
                        {result.phrasen.length > 2 && showMore === 2 && '...'}
                        {result.phrasen.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.phrasen.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {result.phrasen.length > 0 &&
                    result.phrasen[0]?.english.value !== '' &&
                    selectedLanguage === 'ENGLISCH - B/K/S' && (
                      <div
                        className={classes.item}
                        onClick={() => onClickFilterData(result, 'phrasen')}
                      >
                        <h3>Phrasen u. Anwendungsbeispiele</h3>
                        {result.phrasen
                          .slice(0, showMore)
                          .map((phrasen, index) => (
                            <p key={index * 2}>{phrasen[language].value}</p>
                          ))}
                        {result.phrasen.length > 2 && showMore === 2 && '...'}
                        {result.phrasen.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.phrasen.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {/*Fremdworterbuch */}

                  {result.fremdworterbuch.length > 0 &&
                    result.fremdworterbuch[0]?.german.value !== '' &&
                    selectedLanguage === 'B/K/S - DEUTSCH' && (
                      <div
                        className={classes.item}
                        onClick={() =>
                          onClickFilterData(result, 'fremdwörterbuch')
                        }
                      >
                        <h3>Fremdwörterbuch</h3>
                        {result.fremdworterbuch
                          .slice(0, showMore)
                          .map((item, index) => (
                            <p
                              key={index * 2}
                              style={{
                                whiteSpace: 'pre-wrap',
                                marginBottom: '5px',
                              }}
                            >
                              {item[language].value}
                            </p>
                          ))}
                        {result.fremdworterbuch.length > 2 &&
                          showMore === 2 &&
                          '...'}
                        {result.fremdworterbuch.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.fremdworterbuch.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {result.fremdworterbuch.length > 0 &&
                    result.fremdworterbuch[0]?.german.value !== '' &&
                    selectedLanguage === 'DEUTSCH - B/K/S' && (
                      <div
                        className={classes.item}
                        onClick={() =>
                          onClickFilterData(result, 'fremdwörterbuch')
                        }
                      >
                        <h3>Fremdwörterbuch</h3>
                        {result.fremdworterbuch
                          .slice(0, showMore)
                          .map((item, index) => (
                            <p
                              key={index * 2}
                              style={{
                                whiteSpace: 'pre-wrap',
                                marginBottom: '5px',
                              }}
                            >
                              {item[language].value}
                            </p>
                          ))}
                        {result.fremdworterbuch.length > 2 &&
                          showMore === 2 &&
                          '...'}
                        {result.fremdworterbuch.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.fremdworterbuch.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {result.fremdworterbuch.length > 0 &&
                    result.fremdworterbuch[0]?.english.value !== '' &&
                    selectedLanguage === 'B/K/S - ENGLISCH' && (
                      <div
                        className={classes.item}
                        onClick={() =>
                          onClickFilterData(result, 'fremdwörterbuch')
                        }
                      >
                        <h3>Fremdwörterbuch</h3>
                        {result.fremdworterbuch
                          .slice(0, showMore)
                          .map((item, index) => (
                            <p
                              key={index * 2}
                              style={{
                                whiteSpace: 'pre-wrap',
                                marginBottom: '5px',
                              }}
                            >
                              {item[language].value}
                            </p>
                          ))}
                        {result.fremdworterbuch.length > 2 &&
                          showMore === 2 &&
                          '...'}
                        {result.fremdworterbuch.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.fremdworterbuch.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}

                  {result.fremdworterbuch.length > 0 &&
                    result.fremdworterbuch[0]?.english.value !== '' &&
                    selectedLanguage === 'ENGLISCH - B/K/S' && (
                      <div
                        className={classes.item}
                        onClick={() =>
                          onClickFilterData(result, 'fremdwörterbuch')
                        }
                      >
                        <h3>Fremdwörterbuch</h3>
                        {result.fremdworterbuch
                          .slice(0, showMore)
                          .map((item, index) => (
                            <p key={index * 2}>{item[language].value}</p>
                          ))}
                        {result.fremdworterbuch.length > 2 &&
                          showMore === 2 &&
                          '...'}
                        {result.fremdworterbuch.length > 2 && (
                          <div
                            className={classes.showMore}
                            onClick={(e) =>
                              onClickShowMore(e, result.fremdworterbuch.length)
                            }
                          >
                            {showMore === 2 ? (
                              <BsArrowDownCircle />
                            ) : (
                              <BsArrowUpCircle />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default WordsResults;
